

































































import LsDialog from "@/components/ls-dialog.vue";
import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiBasePhoneConfig, apiBasePhoneConfigEdit, apiBaseEmaileConfig, apiBaseEmaileConfigEdit } from '@/api/setting/setting'
import { apiLogout } from '@/api/app'
import { validator } from "@/utils/util";


@Component({
  components: {
    LsDialog,
  },
})
export default class ListsDetail extends Vue {
  /** S Data **/
  loading: any = false;
  dialogVisible = false;
  formdata: any = {
    platform_login_sms_verify: '',
    shopadmin_login_sms_verify: '',
    supplier_login_sms_verify: '',
  };
  form: any = {
    email: '',
  }
  email: any = '';

  rules = {
    email: [{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
    {
      validator: (required: object, value: number, callback: any) =>
        validator("email", value, callback),
      message: "请输入正确的邮箱地址",
      trigger: "blur",
    }
    ]
  }
  /** E Data **/

  /** S Methods **/
  onSubmitphone() {
    this.dialogVisible = true
  }
  onsumbit() {
    apiBasePhoneConfigEdit({ ...this.formdata }).then((res) => {
      this.loading = false
    }).catch((err) => {
      this.loading = false
    })
  }

  onsumbitemail() {
    apiBaseEmaileConfigEdit({ ...this.form }).then((res) => {
      this.loading = false
    }).catch((err) => {
      this.loading = false
    })
  }

  handleClose(done: any) {
    this.$confirm('确认关闭？')
      .then(_ => {
        done();
      })
      .catch(_ => { });
  }

  getphone() {
    apiBasePhoneConfig().then((res: any) => {
      this.formdata = res
    })
  }

  getemail() {
    apiBaseEmaileConfig().then((res: any) => {
      this.form = res
    })
  }
  created() {
    this.getphone()
    this.getemail()
  }
  /** E Methods **/
}

